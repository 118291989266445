<template>
  <div>

    <!--========================================-->
    <!-- Les meves assignatures                 -->
    <!--========================================-->
    <v-hover class="transition-linear-out-slow-in">
      <v-card class="pa-4 shadow-small rounded-lg ">

        <v-card-title class="px-0 py-0">
          <h2 class="title tit font-weight-bold ml-2 mt-2 mb-3" style="font-size: 25px !important;">
            Problemes guardats
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="addDialog=true"
            height="30"
            :ripple="false"
            icon
            class="ml-4 collections-edit-button ">
            <v-icon
            >
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <!--========================================-->
        <!-- Afegir assignatures                    -->
        <!--========================================-->
        <div
          v-if="collections.length === 0"
          class="mt-4 ml-2">

          <v-btn
            color="primary"
            depressed
            @click="addDialog=true"
            :ripple="false"
            large
            class="text-none shadow-small rounded tit">
            <v-icon left>{{mdiPlus}}</v-icon>
            Afegir una col·lecció de problemes
          </v-btn>

          <p class="mt-2 mb-0">Encara no tens cap col·lecció...</p>
          <p>Utilitza les col·leccions per guardar problemes d'una forma classificada.</p>

        </div>


        <!--========================================-->
        <!-- Llista Assignatures                    -->
        <!--========================================-->
        <v-row v-else class="pa-2"
        :key="'refreshKey_' + refreshKey"
        >
          <v-col v-for="collection in collections"
                 :key="collection.collection_id + '_' + refreshKey"
                 cols="6" md="3">
            <v-responsive aspect-ratio="1">
              <v-hover v-slot:default="{ hover }" :key="collection.collection_id + '_ref_' + refreshKey">
                <div class="position-relative d-flex flex-grow-1 flex-column">
                  <v-card
                    @click="$emit('onClick', collection)"
                    color="grey lighten-3"
                    flat
                    height="100%"
                    width="100%"
                    class="rounded-lg align-center pa-1"
                  >
                    <v-row v-if="collection.preview && collection.preview.length > 0" no-gutters>
                      <v-col cols="6" v-for="problem in collection.preview" :key="problem.problem_id">
                        <v-responsive aspect-ratio="1">
                          <v-card color="transparent" tile height="100%"
                          class="d-flex flex-grow-1 flex-column"
                          width="100%">
                            <v-img :src="getImage(problem)" contain height="100%" class="image-preview"></v-img>
                          </v-card>
                        </v-responsive>
                      </v-col>
                    </v-row>
                    <div v-else style="height: 100%; width: 100%" class="text-center d-flex flex-grow-1">
                        <v-responsive aspect-ratio="1">
                            <v-card color="transparent" tile height="100%" width="100%"
                            class="d-flex flex-grow-1"
                            >
                                <v-icon large class="ma-auto">{{mdiBookmarkOffOutline}}</v-icon>
                            </v-card>
                        </v-responsive>
                    </div>
                  </v-card>

                  <!-- Botó d'eliminar -->
                  <v-btn
                    v-if="hover"
                    @click.stop="confirmDelete(collection)"
                    icon
                    small
                    color="error"
                    class="delete-button"
                  >
                    <v-icon>{{ mdiDelete }}</v-icon>
                  </v-btn>
                </div>
              </v-hover>
            </v-responsive>

            <p class="d-inline-block text-truncate title mb-0 mt-1"
               style="flex: 1">
              {{ collection.collection_name }}
              <span class="grey--text text--darken-1 font-weight-regular" style="font-size: 0.85em">
                {{ collection.problem_count || 0 }} {{ collection.problem_count === 1 ? 'problema' : 'problemes' }}
              </span>
            </p>
          </v-col>
        </v-row>


      </v-card>
    </v-hover>

    <!--========================================-->
    <!-- Nova Col·lecció Dialog                   -->
    <!--========================================-->
    <v-dialog v-model="addDialog" max-width="850"
              :fullscreen="$vuetify.breakpoint.xs"
              scrollable
              overlay-color="grey" content-class="rounded-xl shadow-md">
      <v-card class="rounded-xl d-flex flex-column"
              max-height="100%"
              style="overflow-y: hidden"
      >
        <v-card-title class="py-0">
          <h2 class="px-6 py-4" style="font-size: 22px !important;">Nova col·lecció</h2>
          <v-spacer></v-spacer>
          <v-icon @click="addDialog=false">{{mdiClose}}</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <div
          class="px-12 py-4"
          style="min-height: 300px; flex: 1; flex-direction: column; background-color: rgb(245 245 245)">

          <div class="my-auto py-12">
            <p class="mb-2 grey--text text--darken-2">Nom de la col·lecció</p>
            <v-text-field v-model="collectionName"
                          solo
                          flat
                          hide-details
                          class="shadow-small"
            ></v-text-field>
            <div class="d-flex mt-4">
              <v-spacer></v-spacer>
              <v-btn
                @click="createCollecion"
                color="primary"
                :loading="creatingLoader"
                depressed
                large
                class="text-none shadow-small rounded tit px-10">
                Crear
              </v-btn>
            </div>

          </div>

        </div>
      </v-card>
    </v-dialog>

    <!--========================================-->
    <!-- Diàleg de confirmació d'eliminació     -->
    <!--========================================-->
    <v-dialog v-model="deleteDialog" max-width="500" content-class="rounded-xl shadow-md">
      <v-card class="rounded-xl d-flex flex-column"
              max-height="100%"
              style="overflow-y: hidden"
      >
        <v-card-title class="py-0">
          <h2 class="px-6 py-4" style="font-size: 22px !important;">Eliminar col·lecció</h2>
          <v-spacer></v-spacer>
          <v-icon @click="deleteDialog=false">{{mdiClose}}</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <div
          class="px-12 py-4"
          style="min-height: 200px; flex: 1; flex-direction: column; background-color: rgb(245 245 245)">

          <div class="my-auto py-8">
            <p class="mb-2">Estàs segur que vols eliminar la col·lecció <span class="font-weight-bold">{{ collectionToDelete ? collectionToDelete.collection_name : '' }}</span>?</p>
            <p class="red--text">Aquesta acció no es pot desfer i s'eliminaran tots els problemes guardats a la col·lecció.</p>

            <div class="d-flex mt-8">
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-4"
                @click="deleteDialog = false"
              >
                Cancel·lar
              </v-btn>
              <v-btn
                color="error"
                :loading="deletingLoader"
                @click="deleteCollection"
                depressed
                class="text-none shadow-small rounded tit px-6"
              >
                Eliminar
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mdiPlus} from '/src/assets/mdi.json'
import {mdiBookmarkOffOutline} from '/src/assets/mdi.json'
import {mdiClose} from '/src/assets/mdi.json'
import {mdiDelete} from '/src/assets/mdi.json'

export default {
  name: "SavedProblems",
  async mounted() {
    await this.fetchCollections()
  },
  data() {
    return {
      mdiPlus: mdiPlus,
      mdiBookmarkOffOutline: mdiBookmarkOffOutline,
      mdiClose: mdiClose,
      mdiDelete: mdiDelete,
      addDialog: false,
      deleteDialog: false,
      creatingLoader: false,
      deletingLoader: false,
      collections: [],
      collectionName: '',
      collectionToDelete: null,
      refreshKey: 0
    }
  },
  methods: {
    async fetchPreviewCollections(collection) {
      try {
        console.log(`Carregant problemes per a la col·lecció ${collection.collection_id} (${collection.collection_name})`);

        const {data} = await this.axios.get(`/problems/collections/${collection.collection_id}/4`, {
          headers: {
            Authorization: this.$root.token.value
          }
        })

        console.log(`Resposta de problemes per a la col·lecció ${collection.collection_id}:`, data);

        // Assignar les dades de preview a la col·lecció
        if (Array.isArray(data) && data.length > 0) {
          collection.preview = data;
          console.log(`Col·lecció ${collection.collection_id} té ${data.length} problemes de preview`);
        } else {
          collection.preview = [];
          console.log(`Col·lecció ${collection.collection_id} no té problemes de preview`);
        }

        this.refreshKey++;
      } catch (e) {
        console.error(`Error carregant els problemes de la col·lecció ${collection.collection_id}:`, e);
        collection.preview = [];
        this.refreshKey++;
      }
    },
    async createCollecion() {
      try {
        this.creatingLoader = true;
        await this.axios.post('/collections', {
          collection_name: this.collectionName
        }, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        await this.fetchCollections()
      } catch (e) {
        throw e
      } finally {
        this.creatingLoader = false;
        this.addDialog = false;
        this.collectionName = '';
      }
    },
    confirmDelete(collection) {
      this.collectionToDelete = collection;
      this.deleteDialog = true;
    },
    async deleteCollection() {
      if (!this.collectionToDelete) return;

      try {
        this.deletingLoader = true;
        await this.axios.delete(`/collections/${this.collectionToDelete.collection_id}`, {
          headers: {
            Authorization: this.$root.token.value
          }
        });

        // Actualitzar la llista de col·leccions
        await this.fetchCollections();

        // Mostrar missatge d'èxit (si tens algun sistema de notificacions)
        // this.$notify({ type: 'success', text: 'Col·lecció eliminada correctament' });
      } catch (error) {
        console.error('Error eliminant la col·lecció:', error);
        // Mostrar missatge d'error (si tens algun sistema de notificacions)
        // this.$notify({ type: 'error', text: 'Error en eliminar la col·lecció' });
      } finally {
        this.deletingLoader = false;
        this.deleteDialog = false;
        this.collectionToDelete = null;
      }
    },
    getImage(problem) {
      if (!problem || !problem.subject_path || !problem.year || !problem.serie || !problem.problem_id) {
        console.error('Problema invàlid per obtenir la imatge:', problem);
        return '';
      }

      const url = `https://examenselectivitat.cat:3000/api/images/${problem.subject_path}/${problem.year}/${problem.serie}/${problem.problem_id}/problem`;
      console.log(`URL d'imatge generada per al problema ${problem.problem_id}:`, url);
      return url;
    },
    async fetchCollections() {
      try {
        console.log('Carregant col·leccions...');

        const {data} = await this.axios.get('/collections', {
          headers: {
            Authorization: this.$root.token.value
          }
        })

        console.log('Resposta de /collections:', data);

        // Actualitzar per gestionar el nou format de resposta
        if (data && data.collections) {
          this.collections = data.collections;
          console.log('Col·leccions carregades del nou format:', this.collections);
        } else if (Array.isArray(data)) {
          // Compatibilitat amb el format antic
          this.collections = data;
          console.log('Col·leccions carregades del format antic:', this.collections);
        } else {
          console.error('Format de resposta incorrecte:', data);
          this.collections = [];
        }

        console.log(`S'han carregat ${this.collections.length} col·leccions`);

        // Carregar els problemes de cada col·lecció
        if (this.collections && this.collections.length > 0) {
          console.log('Carregant problemes per a cada col·lecció...', this.collections);

          // Utilitzar Promise.all per carregar tots els problemes en paral·lel
          this.collections.forEach(col => this.fetchPreviewCollections(col))

          console.log('Tots els problemes de les col·leccions han estat carregats');
        } else {
          console.log('No hi ha col·leccions per carregar problemes');
        }
      } catch (e) {
        console.error('Error carregant les col·leccions:', e);
        this.collections = [];
        throw e
      }
    }
  },
  watch: {
    '$root.token': {
      immediate: true,
      async handler(val) {
        console.log('Token canviat:', val ? 'Token present' : 'Sense token');
        if (val) {
          try {
            await this.fetchCollections();
          } catch (error) {
            console.error('Error en carregar les col·leccions des del watcher:', error);
          }
        } else {
          console.log('No es poden carregar les col·leccions sense token');
          this.collections = [];
        }
      }
    }
  }
}
</script>

<style scoped>
.collections-edit-button >>> .v-btn--icon {
  height: 20px !important;
  width: 20px !important;
}

.position-relative {
  position: relative;
  height: 100%;
  width: 100%;
}

.delete-button {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
</style>
