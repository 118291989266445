<template>
  <div>

    <!--========================================-->
    <!-- Les meves assignatures                 -->
    <!--========================================-->
    <v-hover class="transition-linear-out-slow-in"
             v-slot:default="{ hover }">
      <v-card class="pa-4 shadow-small rounded-lg ">

        <v-card-title class="px-0 py-0">
          <h2 class="title tit font-weight-bold ml-2 mt-2 mb-3" style="font-size: 25px !important;">
            Les meves assignatures
        </h2>
          <v-spacer></v-spacer>
          <v-btn
            v-if="subjects.length > 0 && hover"
            height="30"
            :ripple="false"
            icon @click="addDialog=true" class="ml-4 subjects-edit-button">
            <v-icon
            > {{mdiPencil}}
            </v-icon>
          </v-btn>
        </v-card-title>
        <!--========================================-->
        <!-- Afegir assignatures                    -->
        <!--========================================-->
        <div
          v-if="subjects.length === 0"
          class="mt-4">
          <p class="body-2">Encara no tens assignatures assignades...</p>

          <v-btn color="primary"
                 @click="addDialog=true"
                 :ripple="false"
                 class="rounded-lg shadow-md d-flex align-center text-none px-10 mb-6"
                 style="border: thin solid #f1f1f1 !important;"
                 max-width="300"
          >
            <v-icon left>{{mdiPlus}}</v-icon>
            Afegir assignatures
          </v-btn>

        </div>


        <!--========================================-->
        <!-- Llista Assignatures                    -->
        <!--========================================-->
        <v-row v-else no-gutters>
          <v-col v-for="subject in subjects" cols="12" md="12">
            <v-card
              @click="$router.push({name: 'Subject', params: {subject_name: subject.subject_name}})"
              color="white"
              flat
              class="rounded-lg d-flex align-center py-2"
            >
              <v-avatar color="#e4e6eb" size="42" class="text-center mx-2">
                <v-icon color="#256EFF" size="23">
                  {{ $vuetify.icons.values[subject.subject_icon] }}

                </v-icon>
              </v-avatar>
              <div class="align-center">
                <p class="d-inline-block text-truncate title mb-0 mt-1"
                   style="flex: 1">
                  {{ subject.subject_name }}
                </p>
              </div>

            </v-card>
          </v-col>
        </v-row>


        <!--========================================-->
        <!-- Add Dialog                             -->
        <!--========================================-->
        <v-dialog v-model="addDialog" max-width="850"
                  :fullscreen="$vuetify.breakpoint.xs"
                  scrollable
                  overlay-color="grey" content-class="rounded-xl shadow-md">

          <add-my-subjects-dialog
            v-model="addDialog"
            :my-subjects="subjects"
            @fetchMySubjects="fetchMySubjects"
          ></add-my-subjects-dialog>
        </v-dialog>

      </v-card>
    </v-hover>

  </div>
</template>

<script>
import {mdiPencil} from '/src/assets/mdi.json'
import {mdiPlus} from '/src/assets/mdi.json'

import AddMySubjectsDialog from "./AddMySubjectsDialog";

export default {
  name: "MySubjects",
  components: {AddMySubjectsDialog},
  data() {
    return {
      mdiPencil: mdiPencil,
      mdiPlus: mdiPlus,
      addDialog: false,
      subjects: []
    }
  },
  methods: {
    async fetchMySubjects() {
      try {
        const {data} = await this.axios.get('/my-subjects', {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.subjects = data;
      } catch (e) {
        throw e
      }
    }
  },
  watch: {
    '$root.token': {
      immediate: true,
      async handler(val) {
        if (val)
          await this.fetchMySubjects();
      }
    }
  }
}
</script>

<style scoped>
.subjects-edit-button >>> .v-btn--icon {
  height: 20px !important;
  width: 20px !important;
}
</style>
